import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import dynamic from 'next/dynamic';

import { getZoneName } from '~/utils';
import DataSourceButton from '~/components/index/DataSourceButton';
const Summary = dynamic(() => import('~/components/index/Summary'), { ssr: false });

export default ({ modifyTime, name }) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="card" id="stat">
        <h2>
          <div>
            {t('stat.title')} {name ? `· ${name}` : false}
          </div>
          <div className="subtitle">
            <span className="due">
              {t('stat.due')}: {dayjs(modifyTime).format('YYYY-MM-DD HH:mm')} {getZoneName()}
            </span>
            <DataSourceButton />
          </div>
        </h2>
        <Summary />
      </div>
      <style jsx>{`
        .due {
          font-size: 0.8rem;
          color: #888;
          font-weight: 400;
        }
        .subtitle {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
        }
      `}</style>
    </>
  );
};
