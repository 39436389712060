export function getMapRanges(dimension) {
  if (dimension === 'tests') {
    const base = 2000;
    return [
      { min: 2000 * base },
      { min: 500 * base, max: 2000 * base },
      { min: 250 * base, max: 500 * base },
      { min: 50 * base, max: 250 * base },
      { min: 1, max: 50 * base },
    ];
  }
  if (dimension === 'population') {
    return [
      { min: 38.4 * 1000000 },
      { min: 26.4 * 1000000, max: 38.4 * 1000000 },
      { min: 19.6 * 1000000, max: 26.5 * 1000000 },
      { min: 11.6 * 1000000, max: 19.6 * 1000000 },
      { min: 8.26 * 1000000, max: 11.6 * 1000000 },
      { min: 5.28 * 1000000, max: 8.26 * 1000000 },
      { min: 2.8 * 1000000, max: 5.28 * 1000000 },
      { max: 2.8 * 1000000 },
    ];
  }
  if (dimension === 'tests1m') {
    const base = 20000;
    return [
      { min: 24 * base },
      { min: 20 * base, max: 24 * base },
      { min: 16 * base, max: 20 * base },
      { min: 12 * base, max: 16 * base },
      { min: 8 * base, max: 12 * base },
      { min: 4 * base, max: 8 * base },
      { min: 1, max: 4 * base },
    ];
  }
  if (dimension === 'cases1m') {
    return [
      { min: 200000 },
      { min: 160000, max: 200000 },
      { min: 120000, max: 160000 },
      { min: 80000, max: 120000 },
      { min: 40000, max: 80000 },
      { min: 1, max: 40000 },
    ];
  }
  if (dimension === 'death10m') {
    return [
      { min: 32000 },
      { min: 28000, max: 32000 },
      { min: 24000, max: 28000 },
      { min: 20000, max: 24000 },
      { min: 16000, max: 20000 },
      { min: 12000, max: 16000 },
      { min: 8000, max: 12000 },
      { min: 4000, max: 8000 },
      { min: 1, max: 4000 },
    ];
  }
  if (dimension === 'provice') {
    return [
      { min: 5000 },
      { min: 1000, max: 5000 },
      { min: 500, max: 1000 },
      { min: 100, max: 500 },
      { min: 20, max: 100 },
      { min: 1, max: 20 },
    ];
  }
  if (dimension === 'new_cases_week_over_week') {
    return [
      { min: 80, max: 100 },
      { min: 60, max: 80 },
      { min: 40, max: 60 },
      { min: 20, max: 40 },
      { min: 10, max: 20 },
      { min: 2, max: 10 },
      { min: -2, max: 2 },
      { min: -10, max: -2 },
      { min: -20, max: -10 },
      { min: -40, max: -20 },
      { min: -60, max: -40 },
      { min: -80, max: -60 },
      { min: -100, max: -80 },
    ];
  }
  return [
    { min: 3200000 },
    { min: 1600000, max: 3200000 },
    { min: 800000, max: 1600000 },
    { min: 400000, max: 800000 },
    { min: 80000, max: 400000 },
    { min: 40000, max: 80000 },
    { min: 8000, max: 40000 },
    { min: 1, max: 8000 },
  ];
}

export function getMapColors(dimension) {
  if (dimension === 'population') {
    return ['#ebf3f7', '#004375'];
  }
  if (dimension === 'vaccine_distribution') {
    return ['#ebf3f7', '#008000'];
  }
  if (dimension === 'vaccination') {
    return ['#ebf3f7', '#008000'];
  }
  if (dimension === 'new_cases_week_over_week') {
    return [
      '#00807c',
      '#22908e',
      '#43a19f',
      '#65b1b1',
      '#86c2c2',
      '#a8d2d4',
      '#c9e3e5',
      '#ebf3f7',
      '#e8d9db',
      '#e5bec0',
      '#e2a4a4',
      '#de8988',
      '#db6f6c',
      '#d85451',
      '#d53a35',
    ];
  }
  return Array.from({ length: 6 })
    .map((_, i) => `hsl(9, 100%, ${95 - i * 9}%)`)
    .concat('#9a1903');
}

export function toDimensionText(key) {
  return {
    cases: 'Cases',
    active: 'Active',
    tests: 'Tests',
    population: 'Population',
    cases1m: 'Cases/M',
    tests1m: 'Tests/M',
    death10m: 'Death/10M',
  }[key];
}
