import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { getZoneName } from '~/utils';
import Summary from '~/components/index/Summary';

export default ({ modifyTime, name }) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="share-card-stat">
        <span className="due">
          {t('stat.due')}: {dayjs(modifyTime).format('YYYY-MM-DD HH:mm')} {getZoneName()}
        </span>
        <Summary share />
      </div>
      <style jsx>{`
        .due {
          font-size: 0.8rem;
          color: #888;
          font-weight: 400;
          padding-left: 5px;
        }
        :global(.share-card-stat strong) {
          font-size: 14px;
        }
      `}</style>
    </>
  );
};
