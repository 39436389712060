import React from 'react';

export default () => {
  const [dimension, setDimension] = React.useState('new_cases_week_over_week');
  const onChangeDimension = event => {
    setDimension(event.detail);
  };

  React.useEffect(() => {
    window.addEventListener('changeDimension', onChangeDimension);
    return () => {
      window.removeEventListener('changeDimension', onChangeDimension);
    };
  }, []);

  React.useEffect(() => {
    if (['cases', 'active'].includes(dimension)) {
      window.dispatchEvent(new CustomEvent('changeDimension', { detail: dimension }));
    }
  }, [dimension]);

  return {
    dimension,
    setDimension,
  };
};
