import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

export default ({ dataType }) => {
  const [visible, setVisible] = React.useState(false);
  const { t } = useTranslation();

  if (dataType === 'vaccine') {
    return (
      <>
        <button onClick={() => setVisible(true)} style={{ cursor: 'pointer' }}>
          {t('stat.data_source')}
        </button>
        <Modal title="Data source" visible={visible} onCancel={() => setVisible(false)} footer={null}>
          Vaccination data from{' '}
          <a
            href="https://github.com/govex/COVID-19"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#999', textDecoration: 'underline' }}>
            govex/COVID-19
          </a>
        </Modal>
        <style jsx>{`
          button {
            color: #fff;
            font-size: 12px;
            background: #497b89;
            border-radius: 4px;
            padding: 3px 5px;
            outline: none;
            border: none;
          }
        `}</style>
      </>
    );
  }

  return (
    <>
      <button onClick={() => setVisible(true)} style={{ cursor: 'pointer' }}>
        {t('stat.data_source')}
      </button>
      <Modal title="Data source" visible={visible} onCancel={() => setVisible(false)} footer={null}>
        We manually aggregate data from official government websites and latest news reports. Our volunteers fact-check
        and de-dup all the updates to bring accurate information. All cases have links to credible sources. Note that
        data are being collected in real-time and are preliminary and subject to change as COVID-19 response continues.{' '}
        <br /> <br />
        The recovered number include both discharged and healed. According to CDC guideline, they may not be fully
        healed or recovered. Also the "cleared from quarantine" cases are not included in Recovery.
        <br /> <br />
        <a href="https://airtable.com/shrxPB5CCBlxLV8wJ">Click here to submit info for us to review. </a>
      </Modal>
      <style jsx>{`
        button {
          color: #fff;
          font-size: 12px;
          background: #497b89;
          border-radius: 4px;
          padding: 3px 5px;
          outline: none;
          border: none;
        }
      `}</style>
    </>
  );
};
