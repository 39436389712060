import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <footer>
        <div className="left">
          <div className="title1">
            <img src="/images/logo-white.png" className="logo" />
            {t('header.1p3a')}
          </div>
          <div className="title2">Global COVID-19 Tracker & Interactive Charts</div>
          <div>coronavirus.1point3acres.com/en</div>
        </div>
        <div>
          <QRCode value="https://coronavirus.1point3acres.com/en" size={100} className="qr-code" />
        </div>
      </footer>
      <style jsx>{`
        footer {
          display: flex;
          font-size: 12px;
          color: #fff;
          width: 100%;
          justify-content: space-between;
          margin-top: 20px;
          height: 100px;
        }
        .left {
          width: 235px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .bottom-text {
          color: #fff;
          font-size: 12px;
          white-space: nowrap;
          transform: scale(0.65);
          transform-origin: left;
        }
        .logo {
          width: 25px;
          height: 25px;
        }
        .title1 {
          font-size: 18px;
        }
        .title2 {
          font-size: 16px;
        }
        footer :global(.qr-code) {
          border: 5px solid #fff;
        }
      `}</style>
    </>
  );
};
