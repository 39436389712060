import _ from 'lodash';
import usStats from '~/data/US-Stats-map';
import caStats from '~/data/Canada-Stats-map';

const getStateStatsByShortName = shortName => {
  return _.find(_.concat(usStats, caStats), item => item.provinceShortName === shortName);
};

export default {
  getStateStatsByShortName,
};
