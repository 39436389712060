import { useTranslation } from 'react-i18next';

export default () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <footer>
        <div className="left">
          <img src="/images/logo-white.png" className="logo" />
          <div>
            <div className="title1">{t('header.1p3a')}</div>
            <div className="title2">{t('share.title2')}</div>
            <div>coronavirus.1point3acres.com</div>
          </div>
        </div>
        <div>
          <img src="/images/qr-web.png" className="qr-code" />
          <div className="qr-help">{t('share.qr')}</div>
        </div>
      </footer>
      <style jsx>{`
        footer {
          display: flex;
          font-size: 12px;
          color: #fff;
          width: 100%;
          justify-content: space-between;
          margin-top: 20px;
          height: 118px;
        }
        .qr-code {
          width: 100px;
          height: 100px;
        }
        .qr-help {
          text-align: center;
        }
        .left {
          font-weight: bold;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .title1 {
          font-size: 18px;
        }
        .title2 {
          font-size: 16px;
        }
        .left-zh .logo {
          width: 45px;
          height: 45px;
        }
        .left-en .logo {
          width: 25px;
          height: 25px;
        }
        .logo {
          width: 45px;
          height: 45px;
        }
      `}</style>
    </>
  );
};
