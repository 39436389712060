import dayjs from 'dayjs';
import _ from 'lodash';
import regression from 'regression';

import {
  getLineChartData,
  getConfirmedCaseData,
  getCuredCaseData,
  getDeathCaseData,
  toChartData,
} from '~/utils/CaseHelper';
import { formatCountryData } from '~/utils/WorldHelper';
import worldData from '~/data/world-data.json';
import regionData from '~/data/region-data-v2.json';
import USCountryCase from '~/data/US-country-case.json';
import USCountryDeath from '~/data/US-country-death.json';
import CanadaCountryCase from '~/data/Canada-country-case.json';
import CanadaCountryDeath from '~/data/Canada-country-death.json';
import CanadaCountryCured from '~/data/Canada-country-cured.json';
import { KeyDates } from '~/common';

export const START_DATE = '2020-2-24';
export const YEAR_2020_PREFIX = '2020/';

export function initialChartData(start, end) {
  const startDate = dayjs(start);
  const length = Math.abs(startDate.diff(end, 'd')) + 1;
  return Array.from({ length }).reduce((r, v, i) => {
    r[startDate.add(i, 'd').format('M/D')] = 0;
    return r;
  }, {});
}

export function chartDataGroupByDate(data) {
  return data.x.reduce((r, v, i) => {
    r[v] = data.total[i];
    return r;
  }, {});
}

export function getFatalityChartData(confirmed, death, country) {
  const deathGroupByDate = chartDataGroupByDate(death);
  const deathOffset =
    country === 'us' ? dayjs(KeyDates.US_DEATH_START_DATE).diff(KeyDates.US_CASE_START_DATE, 'day') : 0;

  return {
    x: confirmed.x,
    total: confirmed.total.map((v, i) =>
      i < deathOffset ? 0 : _.round(((deathGroupByDate[death.x[i - deathOffset]] || 0) / v) * 100, 1)
    ),
  };
}

export function graphicText(text) {
  return {
    type: 'text',
    left: 30,
    top: 65,
    z: 100,
    style: {
      fill: '#c3c3c3',
      text,
      font: 'bold 20px sans-serif',
    },
  };
}

export function getGrowthRate(data) {
  if (_.isEmpty(data) || data.length === 1) {
    return 0;
  }

  const originalDataLength = data.length;
  const sampleStartIndex = Math.max(data.length - 14, 0);
  const sampledData = _.slice(data, sampleStartIndex);

  const dataset = _.zip(
    _.range(sampleStartIndex + 1, originalDataLength + 1),
    _.map(sampledData, d => Math.log(d))
  );

  const [a, b] = regression.linear(dataset).equation;
  return { a, b, sampleStartIndex };
}

export function getGrowth(data, { a, b, sampleStartIndex }) {
  const growth = [];
  if (_.isNil(data)) {
    return growth;
  }

  for (let i = 1; i < data.length + 4; i++) {
    if (i <= sampleStartIndex + 2) {
      growth.push(null); // we only plot days from Day -11 to Day +3
    } else {
      growth.push(Math.pow(Math.E, a * i + b));
    }
  }
  return growth;
}

export function getChartDataByType({ country, state, county, type }) {
  if (country === 'us' && state === '' && county === '') {
    if (type === 'confirmed') {
      return toChartData(USCountryCase);
    } else if (type === 'deaths') {
      return toChartData(USCountryDeath);
    }
  }

  if (country === 'ca' && state === '' && county === '') {
    if (type === 'confirmed') {
      return toChartData(CanadaCountryCase);
    } else if (type === 'deaths') {
      return toChartData(CanadaCountryDeath);
    } else if (type === 'cured') {
      return toChartData(CanadaCountryCured);
    }
  }

  if (['ca', 'us'].includes(country)) {
    const func = {
      confirmed: getConfirmedCaseData,
      deaths: getDeathCaseData,
      recovered: getCuredCaseData,
    };
    return getLineChartData(func[type](country), state, county);
  }
  const stateData = regionData?.[country]?.[state];
  const countryData = worldData?.[country];
  const data = stateData || countryData;
  if (data) {
    return formatCountryData(data[type], data.startDate);
  }

  return null;
}
