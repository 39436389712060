import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

export default () => {
  const { i18n } = useTranslation();

  const donationUrl = `/${i18n.language}/donate`;

  return (
    <>
      <div className="container" style={{ position: 'relative', paddingTop: 18, paddingBottom: 10 }}>
        <div className="message-panel">
          Notice:
          <br /> <br />
          As of February 13th, 2023, we will no longer be updating the COVID-19 Tracker. If you still have a need for
          COVID data, we recommend using{' '}
          <a href="https://github.com/nytimes/covid-19-data" target="_blank">
            nytimes covid data
          </a>
          . <br /> We would like to express our gratitude for your support over the past three years and encourage you
          to continue using our other products here at{' '}
          <a href="https://www.1point3acres.com/" target="_blank">
            1point3acres.com
          </a>{' '}
          :<br />
          <a href="https://www.1point3acres.com/bbs" target="_blank">
            BBS
          </a>
          {' | '}
          <a href="https://www.salarytics.com/" target="_blank">
            Salarytics
          </a>
          {' | '}
          <a href="https://offer.1point3acres.com/" target="_blank">
            OfferDuoDuo
          </a>
          {' | '}
          <a href="https://visa.1point3acres.com/" target="_blank">
            VisaTracker
          </a>
          {' | '}
          <a href="https://jobs.1point3acres.com/" target="_blank">
            JobDuoDuo
          </a>
          <br />
          <br />
          Thanks, <br />
          1Point3Acres Team
        </div>
      </div>
      <style jsx>{`
        div.container {
          margin: 0.5rem;
          position: relative;
          padding: 5px;
          padding-right: 10px;
          background-color: #d6fff1;
          border: 1px solid #85ffd4;
          border-radius: 5px;

          @media screen and (prefers-color-scheme: dark) {
            background-color: var(--dark-lighter-bg-color);
            border: none;
            color: var(--dark-secondary-text-color);
          }

          .message-panel {
            display: inline-block;
            width: calc(100% - 200px);
            vertical-align: top;
            padding-left: 20px;

            @media screen and (max-width: 576px) {
              width: 100%;
              padding-left: 0px;
            }
          }

          .video-panel {
            display: inline-block;
            width: 200px;

            @media screen and (max-width: 576px) {
              width: 100%;
              text-align: center;
              height: 170px;
            }
          }

          & a {
            color: #000;
            @media screen and (prefers-color-scheme: dark) {
              color: var(--dark-text-color);
            }
          }
          strong {
            color: #1890ff;
          }
        }
      `}</style>
    </>
  );
};
