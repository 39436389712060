import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag, Icon } from 'antd';
import _ from 'lodash';

import StateHelper from '~/utils/StateHelper';
import useLocation from '~/hooks/useLocation';

export default () => {
  const { i18n } = useTranslation();
  const { state, setState, county, setCounty } = useLocation();
  if (_.isEmpty(state)) {
    return null;
  }
  return (
    <>
      <span className="province-tag">
        <Tag
          color="#1890ff"
          closable
          onClose={() => {
            setState('');
          }}>
          <Icon type="filter" />
          &nbsp;
          {i18n.language === 'zh' ? _.get(StateHelper.getStateStatsByShortName(state), 'name') : state}
        </Tag>
        {county && (
          <Tag
            color="#1890ff"
            closable
            onClose={() => {
              setCounty('');
            }}>
            <Icon type="filter" />
            &nbsp;
            {county}
          </Tag>
        )}
      </span>
      <style jsx>{`
        .province-tag {
          position: absolute;
          top: 0px;
          right: 15px;
          z-index: 10;
        }
      `}</style>
    </>
  );
};
