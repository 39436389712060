import React from 'react';

import useLocation from '~/hooks/useLocation';
import Map from '~/components/index/Map';
import WorldMap from '~/components/world/WorldMap';

export default ({ share }) => {
  const { country } = useLocation();
  if (['us', 'ca'].includes(country)) {
    return <Map share={share} />;
  }
  return share ? <WorldMap share={share} /> : null;
};
