import React from 'react';
import ReactEcharts from 'echarts-for-react';
import echarts from 'echarts/lib/echarts';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import 'echarts/lib/chart/map';
import 'echarts/lib/component/visualMap';
import classnames from 'classnames';
import { Radio } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { getZoneName } from '~/utils';

import { modifyTime } from '~/data/overall';
import countries from '~/data/countries.json';
import { MAP_COLORS } from '~/utils/Constants';
import ErrorReportButton from '~/components/index/ErrorReportButton';
import * as worldHelper from '~/utils/WorldHelper';
import worldData from '~/data/world-data.json';
import useDimension from '~/hooks/useDimension';
import 'dayjs/locale/zh-cn';

const NO_MAP_REGIONS = ['Wuhan Evacuee', 'Grand Princess', 'Diamond Princess'];

echarts.registerMap('countries', countries);

dayjs.extend(relativeTime);

function Disclaimer() {
  const [displayAll, setDisplayAll] = React.useState(false);
  return (
    <>
      <div className={classnames('disclaimer', { 'display-partial': !displayAll })}>
        [1] The boundaries and names shown and the designations used on this map/table do not imply the expression of
        any opinion whatsoever on the part of the 1Point3Acres concerning the legal status of any country, territory,
        city or area or of its authorities, or concerning the delimitation of its frontiers or boundaries. All the lines
        on maps represent VERY approximate border lines coming in a standard library defined by someone to display
        nicely on a mobile device, and is small enough to not eat too much of your bandwidth.
        <br />
        [2] Diseases are apolitical and impact all human lives. We do our best to bring information about this pandemic
        against human beings (NOT ANY political sovereignties).
        <br />
        [3] We use location / region / countries as the data sources define them. There is no way for us to further
        dissect or arbitrarily merge. We have zero political agenda. We simply show you data as collected by publicly
        recognized sources.
        <br />
        [4] No two sources define everything in the same way. Reporting vary from location to location. We try to follow
        the convention adopted by the most by local regions.
        <br />
        [5] We apologize if the definition offends anyone. We have done our best to curate the information and will not
        do further revisions. Nothing will ever please everyone. If you oppose to how the regions are defined, feel free
        to use CDC, WHO, JHU and other fine trackers. We are not going to debate about how to define them or make
        further revisions. If we had spent more time trying to please everyone, you would never have seen this page.
        {!displayAll && (
          <div className="show-more-button" onClick={() => setDisplayAll(true)}>
            Show more
          </div>
        )}
      </div>
      <style jsx>{`
        .disclaimer {
          color: gray;
          font-size: 10px;
        }
        .display-partial {
          height: 100px;
          overflow: hidden;
          position: relative;
        }
        .show-more-button {
          position: absolute;
          bottom: 0;
          height: 22px;
          font-size: 11px;
          border-radius: 5px;
          background-image: linear-gradient(white, #d3d3d3);
          width: 100%;
          opacity: 0.8;
          text-align: center;
          cursor: pointer;
        }
      `}</style>
    </>
  );
}

function Map({ country, share }) {
  const { t, i18n } = useTranslation();
  const [mapToken, setMapToken] = React.useState(0);
  const { dimension, setDimension } = useDimension();
  const updateTime = i18n.language === 'zh' ? dayjs(modifyTime).locale('zh-cn') : dayjs(modifyTime);
  const isLatest = dayjs().diff(updateTime, 'hour') <= 24;

  const getOption = data => {
    return {
      visualMap: {
        show: true,
        type: 'piecewise',
        min: 0,
        max: 2000,
        align: 'right',
        top: '40%',
        right: 0,
        left: 'auto',
        inRange: {
          color: MAP_COLORS,
        },
        pieces: [
          { min: 100000 },
          { min: 10000, max: 100000 },
          { min: 1000, max: 10000 },
          { min: 100, max: 1000 },
          { max: 100 },
        ],
        padding: 5,
        // "inverse": false,
        // "splitNumber": 5,
        orient: 'vertical',
        showLabel: true,
        text: [t('map.high'), t('map.low')],
        itemWidth: 10,
        itemHeight: 10,
        textStyle: {
          fontSize: 10,
          color: '#696969',
        },
        hoverLink: false,
      },
      series: [
        {
          left: 'center',
          // top: '15%',
          // bottom: '10%',
          type: 'map',
          name: '确诊人数',
          label: {
            show: false, //!isUSProvice,
            position: 'inside',
            // margin: 8,
            fontSize: 6,
            color: '#696969',
          },
          mapType: 'countries', //map, //isUSProvice ? provice : map,
          data: data
            .filter(({ name }) => (country ? name === country : true))
            .map(({ name, confirmed, active }) => {
              return {
                name,
                value: dimension === 'active' ? active : confirmed,
              };
            }),
          zoom: 1.0,
          scaleLimit: {
            min: 1,
          },
          showLegendSymbol: false,
          silent: true,
          rippleEffect: {
            show: true,
            brushType: 'stroke',
            scale: 2.5,
            period: 4,
          },
          itemStyle: {
            borderColor: '#A9A9A9',
          },
        },
      ],
      tooltip: {
        show: false,
      },
    };
  };

  return (
    <>
      <div className="card" id="map">
        {share || (
          <h2>
            {t('map.title')}
            <div className="subtitle">
              <span className="due">
                {isLatest ? t('news.relative') : t('stat.due')}:{' '}
                {isLatest ? updateTime.fromNow() : updateTime.format('YYYY-MM-DD HH:mm')}{' '}
                {isLatest ? ' ' : getZoneName()}
              </span>
              <ErrorReportButton />
            </div>
          </h2>
        )}
        <div>
          {share || (
            <Radio.Group
              size="small"
              value={dimension}
              onChange={e => setDimension(e.target.value)}
              className="radio-og">
              <Radio.Button value="cases">{t('stat.confirmed')}</Radio.Button>
              <Radio.Button value="active">{t('stat.active')}</Radio.Button>
            </Radio.Group>
          )}
          {React.useMemo(
            () => (
              <ReactEcharts
                echarts={echarts}
                option={getOption(worldHelper.CUMULATIVE_WORLD_DATA)}
                lazyUpdate={true}
                style={{ height: share ? '200px' : '300px' }}
              />
            ),
            [mapToken, dimension, i18n.language]
          )}
        </div>
        {share || <Disclaimer />}
      </div>
      <style jsx>{`
        h2 {
          padding-right: 0px;
        }
        .due {
          font-size: 0.8rem;
          color: #888;
          font-weight: 400;
        }
        .tab-container {
          position: relative;
          height: 500px;
        }
        .tab-container > div :global(.ant-table-wrapper) {
          display: none;
        }
        .tab-container > div.active :global(.ant-table-wrapper) {
          display: block;
        }
        .button {
          color: #fff;
          font-size: 12px;
          background: #ee7500;
          border-radius: 4px;
          padding: 3px 5px;
          font-weight: normal;
          margin-left: 10px;
          cursor: pointer;
        }
        .tab-menu {
          padding: 0px;
        }
        .tips {
          text-align: center;
          font-size: 10px;
          color: gray;
        }
        h3 {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
        }
        .subtitle {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
        }
        .button-og {
          position: absolute;
          top: 10px;
          right: 0px;
          z-index: 10;
        }
      `}</style>
    </>
  );
}

export default Map;
