import React from 'react';
import ReactEcharts from 'echarts-for-react';
import echarts from 'echarts/lib/echarts';
import axios from 'axios';
import classNames from 'classnames';
import { Tag, Icon, Radio, Badge, message } from 'antd';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import 'echarts/lib/chart/map';
import 'echarts/lib/component/visualMap';

import us from '~/data/us.json';
import ca from '~/data/canada.json';
import USMap from '~/data/US-Stats-map';
import CanadaMap from '~/data/Canada-Stats-map';
import testStatesData from '~/data/test-states-overall';
import populationData from '~/data/state-population';
import USVaccineDistribution from '~/data/us-vaccine-distribution';
import USVaccineDosesAdmin from '~/data/us-vaccine-doses-admin';
import usStatesReversed from '~/data/us-states-reversed';
import { getStateMapData } from '~/utils/CaseHelper';
import {
  getStateTableData,
  checkAndFetchFullUSCAData,
  getUSVaccineDistributedData,
  getUSVaccineAdministeredData,
} from '~/utils/CaseHelper';
import { thousandSeparate, formatNumberWithPrefix } from '~/utils';

import StateHelper from '~/utils/StateHelper';
import ErrorReportButton from '~/components/index/ErrorReportButton';
import * as mapHelper from '~/utils/MapHelper';
import useDimension from '~/hooks/useDimension';
import useLocation from '~/hooks/useLocation';
import useWindowDimensions from '~/hooks/useWindowDimensions';
import DeferredRender from '~/components/DeferredRender';

const NO_MAP_REGIONS = ['Wuhan Evacuee', 'Grand Princess', 'Diamond Princess'];

echarts.registerMap('us', us, {
  AK: {
    left: -131,
    top: 25,
    width: 15,
  },
  HI: {
    left: -110,
    top: 25,
    width: 5,
  },
  PR: {
    left: -77,
    top: 25,
    width: 2,
  },
});
echarts.registerMap('ca', ca);

function Map({ share }) {
  const { country, setCountry, state, setState, setCounty } = useLocation();
  if (!['us', 'ca'].includes(country)) return null;

  const { t, i18n } = useTranslation();
  const [mapToken, setMapToken] = React.useState(0);
  const [stateMapData, setStateMapData] = React.useState({});
  const { dimension, setDimension } = useDimension();
  const { width: windowWidth } = useWindowDimensions();
  const isMobile = windowWidth <= 576;

  const isUSProvice = country === 'us' && state !== '';

  const handleChangeProvice = name => {
    setState(name);
  };

  React.useEffect(() => {
    if (isUSProvice && !_.includes(NO_MAP_REGIONS, state)) {
      axios(`/resources/maps/us_map/${state}.json`).then(response => {
        const map = response.data;
        echarts.registerMap(state, map);

        const data = _.transform(
          getStateMapData(state),
          (result, value, key) => {
            const fullCounty = key.split('--')[0];
            const confirmedNum = parseInt(value['value'], 10);
            const deadNum = parseInt(value['deadCount'], 10);
            if (fullCounty in result) {
              result[fullCounty]['value'] = result[fullCounty]['value'] + confirmedNum;
              result[fullCounty]['deadCount'] = result[fullCounty]['deadCount'] + deadNum;
            } else {
              result[fullCounty] = { value: confirmedNum, deadCount: deadNum };
            }
          },
          {}
        );

        setStateMapData(Object.entries(data).map(([name, v]) => ({ ...v, name })));
        setMapToken(Date.now());
      });
    } else {
      setMapToken(Date.now());
    }
  }, [state, country]);

  const getOption = (map, data) => {
    return {
      visualMap: {
        show: true,
        type: 'piecewise',
        min: 0,
        max: 2000,
        align: 'right',
        top: dimension === 'new_cases_week_over_week' ? '10%' : '40%',
        right: -5,
        left: 'auto',
        inRange: {
          color: mapHelper.getMapColors(share ? 'new_cases_week_over_week' : dimension),
        },
        pieces: mapHelper.getMapRanges(
          country === 'us' && state
            ? dimension === 'new_cases_week_over_week' || share
              ? 'new_cases_week_over_week'
              : 'state'
            : dimension
        ),
        padding: 5,
        // "inverse": false,
        // "splitNumber": 5,
        orient: 'vertical',
        showLabel: !share,
        text: [t('map.high'), t('map.low')],
        itemWidth: 10,
        itemHeight: 10,
        textStyle: {
          fontSize: 9,
          color: '#696969',
        },
        formatter: (v1, v2) => {
          const suffix = dimension === 'new_cases_week_over_week' ? '%' : '';
          if (!isFinite(v1) && !isFinite(v2)) {
            return '';
          }
          if (!isFinite(v2)) {
            return `${thousandSeparate(v1)}${suffix} and more`;
          }
          if (!isFinite(v1)) {
            return `${thousandSeparate(v2)}${suffix} and less`;
          }
          return `${thousandSeparate(v1)}${suffix} - ${thousandSeparate(v2)}${suffix}`;
        },
      },
      series: [
        {
          left: 'center',
          // top: '15%',
          // bottom: '10%',
          type: 'map',
          name: '确诊人数',
          silent: false,
          label: {
            show: !isUSProvice,
            position: 'inside',
            // margin: 8,
            fontSize: 6,
            color: '#696969',
          },
          mapType: isUSProvice ? state : map,
          data,
          zoom: 1.0,
          roam: true,
          scaleLimit: {
            min: 0.8,
            max: 15,
          },
          showLegendSymbol: false,
          emphasis: {},
          rippleEffect: {
            show: true,
            brushType: 'stroke',
            scale: 2.5,
            period: 4,
          },
          itemStyle: {
            borderColor: '#A9A9A9',
          },
        },
      ],
      tooltip: {
        trigger: 'item',
        formatter: function(params) {
          if (Number.isNaN(params.value)) return '';

          if (share || dimension === 'new_cases_week_over_week') {
            return `
              <div style="font-size:12px; line-height: 16px">
                ${t('map.new_cases_week_over_week_note')}<br/>
                ${params.name}&nbsp;&nbsp;${thousandSeparate(
              _.round(params.data.secondaryValue * 7)
            )}&nbsp;&nbsp;${`${formatNumberWithPrefix(_.round(params.value, 2))}%`}<br />
                ${
                  _.isNil(params.data.secondaryValue)
                    ? ''
                    : `${_.round(params.data.secondaryValue)} ${t('map.daily_cases_7_day_moving_average')}`
                }<br />
              </div>
            `;
          }

          if (dimension === 'tests') {
            const {
              name,
              value,
              data: { positive, pending },
            } = params;
            return `
              <div style="font-size:12px; line-height: 16px">
                ${params.name}<br/>
                Tested: ${value}<br />
                Positive%: ${_.round((positive / (value - pending)) * 100, 1)}%<br />
                ${pending ? `pending: ${pending}<br />` : ''}
              </div>
            `;
          }

          if (dimension === 'cases') {
            const emergency = params?.data?.emergency;
            const probableDeadCount = params?.data?.probableDeadCount;
            return `
              <div style="font-size:12px; line-height: 16px">
                ${params.name}<br/>
                ${t('map.confirmed')}: ${params.value || '0'}<br />
                ${t('map.dead')}: ${params?.data?.deadCount || '0'}<br />
                ${country === 'us' ? '' : `${t('stat.recovered')}: ${params?.data?.curedCount || '0'}<br />`}
                ${probableDeadCount ? `${t('map.probable_death')}: ${probableDeadCount}<br />` : ''}
                ${emergency ? `${t('map.emergency')}: ${emergency}` : ''}
              </div>
            `;
          }

          if (dimension === 'vaccine_distribution') {
            const { name, value } = params;
            return `
              <div style="font-size:12px; line-height: 16px">
                ${params.name}<br/>
                Total ${t('map.first_doses_allotted')}: ${thousandSeparate(params.data.value)}<br />
                Pfizer ${t('map.first_doses_allotted')}: ${thousandSeparate(
              params.data.total_pfizer_allocation_first_dose_shipments
            )}<br />
                Moderna ${t('map.first_doses_allotted')}: ${thousandSeparate(
              params.data.total_moderna_allocation_first_dose_shipments
            )}<br />
              </div>
            `;
          }

          if (dimension === 'vaccination') {
            const { doses_admin_pfizer, doses_admin_moderna } = params.data;
            return `
              <div style="font-size:12px; line-height: 16px">
                ${params.name} ${t('map.doses_administered')}<br/>
                ${t('chart.total')}: ${thousandSeparate(params.value)}<br/>
                ${doses_admin_pfizer ? `Pfizer: ${thousandSeparate(doses_admin_pfizer)}<br />` : ''}
                ${doses_admin_moderna ? `Moderna: ${thousandSeparate(doses_admin_moderna)}<br />` : ''}
              </div>
            `;
          }

          const { name, value } = params;
          return `
              <div style="font-size:12px; line-height: 16px">
                ${params.name}<br />
                ${mapHelper.toDimensionText(dimension)}: ${value}
              </div>
            `;
        },
      },
    };
  };

  const formatData = country => {
    const data = {
      us: USMap,
      ca: CanadaMap,
    }[country];
    if (share || dimension === 'new_cases_week_over_week') {
      const stateTableData = getStateTableData(country);
      if (isUSProvice && !_.isEmpty(stateMapData)) {
        return stateMapData.map(d => {
          const stateItem = _.find(stateTableData, item => item.provinceShortName === state);
          if (_.isNil(stateItem)) {
            return {
              name: d.name,
              value: null,
              secondaryValue: null,
            };
          }
          const countyItem = _.find(stateItem.counties, item => item.name === d.name);
          if (_.isNil(countyItem)) {
            return {
              name: d.name,
              value: null,
              secondaryValue: null,
            };
          }
          const { confirmed, oneWeekIncreaseConfirmed, twoWeekIncreaseConfirmed } = countyItem;
          return {
            name: d.name,
            value:
              oneWeekIncreaseConfirmed === twoWeekIncreaseConfirmed
                ? null
                : (oneWeekIncreaseConfirmed / (twoWeekIncreaseConfirmed - oneWeekIncreaseConfirmed) - 1) * 100,
            secondaryValue: confirmed === oneWeekIncreaseConfirmed ? null : oneWeekIncreaseConfirmed / 7,
          };
        });
      }
      return data.map(d => {
        const dataItem = _.find(stateTableData, item => item.provinceShortName === d.provinceShortName);
        if (_.isNil(dataItem)) {
          return {
            name: d.provinceShortName,
            value: null,
            secondaryValue: null,
          };
        }
        const { confirmed, oneWeekIncreaseConfirmed, twoWeekIncreaseConfirmed } = dataItem;
        return {
          name: d.provinceShortName,
          value:
            oneWeekIncreaseConfirmed === twoWeekIncreaseConfirmed
              ? null
              : (oneWeekIncreaseConfirmed / (twoWeekIncreaseConfirmed - oneWeekIncreaseConfirmed) - 1) * 100,
          secondaryValue: confirmed === oneWeekIncreaseConfirmed ? null : oneWeekIncreaseConfirmed / 7,
        };
      });
    }
    if (dimension === 'tests') {
      return testStatesData.map(t => ({
        name: t.state,
        value: t.total,
        positive: t.positive,
        pending: t.pending,
      }));
    }
    if (dimension === 'population') {
      return Object.entries(populationData).map(([k, v]) => ({
        name: k,
        value: v.replace(/,/g, ''),
      }));
    }
    if (dimension === 'tests1m') {
      return testStatesData.map(t => {
        const name = t.state;
        return {
          name,
          value: populationData[name] ? parseInt((t.total / populationData[name].replace(/,/g, '')) * 1000000) : null,
        };
      });
    }
    if (dimension === 'cases1m') {
      return data.map(d => {
        const name = d.provinceShortName;
        return {
          name,
          value: populationData[name]
            ? parseInt((d.confirmedCount / populationData[name].replace(/,/g, '')) * 1000000)
            : null,
        };
      });
    }
    if (dimension === 'death10m') {
      return data.map(d => {
        const name = d.provinceShortName;
        return {
          name,
          value: populationData[name]
            ? parseInt((d.deadCount / populationData[name].replace(/,/g, '')) * 1000 * 1000 * 10)
            : null,
        };
      });
    }
    if (dimension === 'active') {
      return data.map(d => {
        return {
          name: d.provinceShortName,
          value: d.confirmedCount - d.deadCount - d.curedCount,
        };
      });
    }
    if (dimension === 'vaccine_distribution') {
      return getUSVaccineDistributedData();
    }
    if (dimension === 'vaccination') {
      return getUSVaccineAdministeredData();
    }
    return isUSProvice
      ? stateMapData
      : data.map(({ provinceShortName, confirmedCount, deadCount, emergency, curedCount, probableDeadCount }) => {
          return {
            name: provinceShortName,
            value: confirmedCount,
            deadCount,
            emergency,
            curedCount,
            probableDeadCount,
          };
        });
  };

  const MapTips = () => {
    if (dimension === 'vaccine_distribution') {
      return (
        <div className="map-tips">
          Data from CDC{' '}
          <a
            href="https://data.cdc.gov/Vaccinations/COVID-19-Vaccine-Distribution-Allocations-by-Juris/saz5-9hgg"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#999', textDecoration: 'underline' }}>
            COVID-19 Vaccine Distribution Allocations by Jurisdiction
          </a>
        </div>
      );
    }

    if (dimension === 'vaccination') {
      return (
        <div className="map-tips">
          Data from{' '}
          <a
            href="https://github.com/govex/COVID-19"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#999', textDecoration: 'underline' }}>
            govex/COVID-19
          </a>
        </div>
      );
    }

    return (
      <>
        <div className="map-tips">{t('map.notes')}</div>
        <div className="map-tips">{t('map.notes2')}</div>
      </>
    );
  };

  return (
    <>
      <div className="card" id="map">
        {share || (
          <h2>
            <div className="subtitle">
              {t('map.title')}
              <ErrorReportButton />
            </div>
          </h2>
        )}
        <div className="map-content">
          <div>
            {share || (
              <>
                <Radio.Group
                  size="small"
                  value={dimension}
                  onChange={e => setDimension(e.target.value)}
                  className="map-dimension">
                  <Radio.Button value="new_cases_week_over_week">{t('map.new_cases_week_over_week')}</Radio.Button>
                  <Radio.Button value="cases">{t('map.case')}</Radio.Button>
                  {!state && (
                    <>
                      {country === 'us' && (
                        <>
                          {/* <Radio.Button value="vaccine_distribution">
                            {t('map.vaccine_distribution')}
                            <Badge dot style={{ marginTop: -8 }} />
                          </Radio.Button> */}
                          <Radio.Button value="vaccination">
                            {t('map.vaccination')}
                            <Badge dot style={{ marginTop: -8 }} />
                          </Radio.Button>
                        </>
                      )}
                      {country !== 'us' && <Radio.Button value="active">{t('map.active')}</Radio.Button>}
                      {country === 'us' && (
                        <>
                          <Radio.Button value="tests">{t('map.test')}</Radio.Button>
                          <Radio.Button value="population">
                            Pop<sup>n</sup>
                          </Radio.Button>
                          <Radio.Button value="cases1m">{t('map.case_m')}</Radio.Button>
                          <Radio.Button value="tests1m">{t('map.test_m')}</Radio.Button>
                          <Radio.Button value="death10m">{t('map.death_10m')}</Radio.Button>
                        </>
                      )}
                    </>
                  )}
                </Radio.Group>
                <div style={{ position: 'absolute', top: 43, right: 0, zIndex: 10 }}>
                  {state && (
                    <span className="button" onClick={() => handleChangeProvice('')}>
                      {t('map.show_national_map')}
                    </span>
                  )}
                  <span
                    className="button"
                    onClick={() => {
                      setMapToken(Date.now());
                    }}>
                    {t('map.reset_map')}
                  </span>
                </div>
                {state && (
                  <div>
                    We have no access to County level test data. Please reach out to government agencies for this.
                  </div>
                )}
              </>
            )}
            <DeferredRender deps={[dimension, mapToken, i18n.language]}>
              <ReactEcharts
                echarts={echarts}
                option={getOption(country, formatData(country))}
                lazyUpdate={true}
                style={{ height: share ? '200px' : isMobile ? '250px' : '350px' }}
                onEvents={{
                  async click(e) {
                    if (
                      !isUSProvice &&
                      !_.includes(NO_MAP_REGIONS, e.name) &&
                      _.includes(['cases', 'new_cases_week_over_week'], dimension)
                    ) {
                      if (_.includes(['us', 'ca'], country)) {
                        await checkAndFetchFullUSCAData(() => handleChangeProvice(e.name, country), country);
                      } else {
                        handleChangeProvice(e.name);
                      }
                    } else if (state) {
                      setCounty(e.name);
                    }
                  },
                }}
              />
            </DeferredRender>
            {share || (
              <>
                <MapTips />
                <h3>
                  <span>
                    {t(`table.${country}`)}
                    &nbsp;&nbsp;
                    {!_.isEmpty(state) && (
                      <Tag
                        color="#1890ff"
                        closable
                        onClose={() => {
                          handleChangeProvice('');
                        }}
                        style={{ verticalAlign: 'middle' }}>
                        <Icon type="filter" />
                        &nbsp;
                        {i18n.language === 'zh' ? _.get(StateHelper.getStateStatsByShortName(state), 'name') : state}
                      </Tag>
                    )}
                  </span>
                </h3>
              </>
            )}
          </div>
        </div>
      </div>
      <style jsx>{`
        .card {
          margin-bottom: 0px;
          padding-bottom: 0px;
        }
        .map-content {
          position: relative;
        }
        h2 {
          padding-right: 0px;
        }
        .button {
          color: #fff;
          font-size: 12px;
          background: #497b89;
          border-radius: 4px;
          padding: 3px 5px;
          font-weight: normal;
          margin-left: 10px;
          cursor: pointer;
        }
        :global(.map-tips) {
          text-align: center;
          font-size: 10px;
          color: gray;
        }
        h3 {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
        }
        .subtitle {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
        }
        .button-og {
          position: absolute;
          top: 40px;
          right: 0px;
          z-index: 10;
        }
        :global(.map-dimension) {
          margin-top: 10px;
          width: 100%;
          overflow-x: scroll;
          white-space: nowrap;
          -ms-overflow-style: none;
          scrollbar-width: none;
          :global(.ant-radio-button-wrapper) {
            height: 30px !important;
            border-radius: 4px !important;
            margin: 0 2px;
            vertical-align: top !important;
            font-size: 12px;
            line-height: 25px;
            background: #f7f7f7 !important;
            color: #828282;
            :global(span) {
              white-space: normal;
              overflow-wrap: break-word;
            }
          }
          :global(.map-dimension.ant-radio-button-wrapper-checked) {
            border-color: #c9e5ed !important;
            background: #cbddf6 !important;
            span {
              color: #497b89;
            }
          }
        }
        :global(.map-dimension)::-webkit-scrollbar {
          display: none;
        }
        @media screen and (min-width: 576px) {
          :global(.map-dimension) {
            text-align: center;
          }
        }
      `}</style>
    </>
  );
}

export default Map;
