import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

export default () => {
  const [visible, setVisible] = React.useState(false);
  const { t, i18n } = useTranslation();
  return (
    <>
      <button onClick={() => setVisible(true)} style={{ cursor: 'pointer' }}>
        {t('stat.error_report')}
      </button>
      <Modal title={t('stat.error_report')} visible={visible} onCancel={() => setVisible(false)} footer={null}>
        {i18n.language !== 'zh' ? (
          <span>
            If you notice anything wrong or missing, please
            <a href="https://airtable.com/shrxPB5CCBlxLV8wJ"> notify us here</a>. We will investigate it within 5-15
            minutes.
          </span>
        ) : (
          <span>
            如果您发现数据有误，请<a href="https://airtable.com/shrxPB5CCBlxLV8wJ">点这里通知我们</a>.
            我们将在5-15分钟内解决。
          </span>
        )}
      </Modal>
      <style jsx>{`
        button {
          color: #fff;
          font-size: 12px;
          background: #497b89;
          border-radius: 4px;
          padding: 3px 5px;
          outline: none;
          border: none;
        }
      `}</style>
    </>
  );
};
