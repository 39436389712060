import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { useTranslation } from 'react-i18next';
import { Radio, Icon } from 'antd';
import _ from 'lodash';

import { getFatalityChartData, graphicText, getGrowthRate, getGrowth, getChartDataByType } from '~/utils/ChartHelper';
import useLogScale from '~/hooks/useLogScale';
import useColorScheme from '~/hooks/useColorScheme';
import useWindowDimensions from '~/hooks/useWindowDimensions';
import CDCGuide from '~/components/index/CDCGuide';
import useLocation from '~/hooks/useLocation';
import DeferredRender from '~/components/DeferredRender';
import ProvinceTag from '~/components/index/ProvinceTag';
import { ECHARTS_DATA_ZOOM_HANDLE_ICON, DARK_TEXT_COLOR } from '~/utils/Constants';
import { formatLargeNumber, thousandSeparate, formatAxisLable } from '~/utils';

function addDottedLine(serie) {
  if (serie.data.length <= 1) {
    return [serie];
  }
  return [
    {
      ...serie,
      data: serie.data.slice(0, -1),
    },
    {
      ...serie,
      data: [...Array(serie.data.length - 2).fill(null), ...serie.data.slice(-2)],
      lineStyle: { type: 'dotted' },
    },
  ];
}

function formartTooltip(param) {
  if (param) {
    return `${param.marker}${param.seriesName}: ${thousandSeparate(param.data || 0)}`;
  }
  return '';
}

function max(data) {
  if (data) {
    const value = String(Math.max(...data));
    return Number(value.charAt(0)) + 1 + ''.padEnd(value.length - 1, 0);
  }
  return 0;
}

function getOption({
  data,
  dimension,
  type,
  colors,
  names,
  rate,
  graphic,
  plotTrend,
  t,
  lang,
  titleText,
  isDarkScheme,
  share,
}) {
  const showIncrease = !rate;

  function getYAxis(name, yAxisColor, max) {
    const yAxis = {
      name,
      nameLocation: 'end',
      nameGap: 10,
      nameTextStyle: {
        fontSize: 10,
        align: 'right',
      },
      type: rate ? 'value' : type,
      min(value) {
        if (rate || type === 'value') {
          return Math.max(0, _.floor(value.min * 0.8, -3));
        }
        return Number('1'.padEnd(String(parseInt(value.min)).length, 0));
      },
      axisLine: {
        show: false,
        lineStyle: {
          color: dimension === 'fatality' ? '#999' : yAxisColor,
        },
      },
      splitLine: {
        lineStyle: {
          color: isDarkScheme ? '#2f2f2f' : 'rgba(0, 0, 0, 0.11)',
        },
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        margin: 5,
        formatter: rate ? '{value}%' : value => formatLargeNumber(value, lang),
      },
    };
    if (type === 'value' && dimension !== 'fatality') {
      yAxis.max = max;
      yAxis.interval = max / 5;
    }
    return yAxis;
  }

  return {
    title: {
      text: titleText,
      left: 'center',
      textStyle: {
        fontSize: 14,
        ...(isDarkScheme ? { color: DARK_TEXT_COLOR } : {}),
      },
    },
    tooltip: {
      trigger: 'axis',
      formatter(params) {
        const filteredParams = _.filter(params, param => param.seriesName !== 'trend');
        if (_.isEmpty(filteredParams)) {
          return '';
        }
        return `
          ${filteredParams[0].name}<br/>
          ${formartTooltip(filteredParams[0])}${rate ? '%' : ''}<br />
          ${dimension === 'new' ? formartTooltip(filteredParams[1]) + '<br />' : ''}
        `;
      },
    },
    grid: {
      left: 5,
      right: 10,
      top: 75,
      bottom: 20,
      containLabel: true,
    },
    ...(!share
      ? {
          dataZoom: {
            handleIcon: ECHARTS_DATA_ZOOM_HANDLE_ICON,
            handleSize: '110%',
            height: 20,
            bottom: 0,
          },
        }
      : {}),
    legend: {
      data: names,
      selectedMode: false,
      right: 10,
      top: 43,
      textStyle: {
        ...(isDarkScheme ? { color: DARK_TEXT_COLOR } : {}),
      },
    },
    graphic,
    xAxis: {
      type: 'category',
      axisLine: {
        lineStyle: {
          color: '#999999',
        },
      },
      axisLabel: {
        formatter: formatAxisLable,
      },
      data: _.concat(data.x, '', '', '', ''),
    },
    yAxis: {
      new: getYAxis(t('chart.people_count'), colors[1], max(data.increase)),
      cumulative: getYAxis(t('chart.people_count'), colors[0], max(data.total)),
      fatality: getYAxis('', colors[1], max(data.total)),
    }[dimension],
    series: [
      ...(dimension !== 'new'
        ? addDottedLine({
            type: 'line',
            smooth: true,
            name: names[0],
            data: data.total,
            color: colors[0],
          })
        : []),
      ...(dimension === 'new' && showIncrease
        ? [
            {
              type: 'bar',
              barWidth: '80%',
              smooth: true,
              name: names[0],
              data: type === 'value' ? data.increase : data.increase.map(v => (v === 0 ? null : v)),
              color: colors[1],
              itemStyle: {
                opacity: 0.6,
              },
              emphasis: {
                itemStyle: {
                  color: '#F1D433',
                },
              },
            },
          ]
        : []),
      ...(dimension === 'new' && showIncrease
        ? [
            {
              type: 'line',
              smooth: true,
              name: names[1],
              data:
                type === 'value'
                  ? data.oneWeekAverageIncrease
                  : data.oneWeekAverageIncrease.map(v => (v === 0 ? null : v)),
              color: colors[1],
            },
          ]
        : []),
      ...(dimension !== 'new' && Boolean(plotTrend)
        ? [
            {
              type: 'line',
              name: 'trend',
              color: '#CECECE',
              symbolSize: 1,
              label: {
                show: true,
                offset: [-30, 0],
                formatter(params) {
                  if (params.dataIndex === data.total.length + 2) {
                    const series = data.total;
                    return `${t('chart.daily_growth_rate')}: ${Math.round(getGrowthRate(series).a * 100)}%`;
                  }
                  return '';
                },
              },
              markPoint: {
                symbol: 'none',
              },
              data: getGrowth(data.total, getGrowthRate(data.total)),
              lineStyle: { type: 'dashed', width: 1 },
            },
          ]
        : []),
    ],
  };
}

export default ({ view, share = false }) => {
  const [type, setType] = useLogScale();
  const [dimension, setDimension] = React.useState('new');
  const { country, state, setState, county, setCounty } = useLocation();
  const { isDarkScheme } = useColorScheme();
  const { width: windowWidth } = useWindowDimensions();
  const isMobile = windowWidth <= 576;

  const { t, i18n } = useTranslation();

  if (!country) return null;

  const populateOneWeekAverageIncrease = chartData => {
    if (_.isNil(chartData)) {
      return;
    }
    chartData.oneWeekAverageIncrease = _.map(chartData.total, (val, index) => {
      if (index < 7) {
        return _.round((val - (chartData.total[0] - chartData.increase[0])) / (index + 1));
      } else {
        return _.round((val - chartData.total[index - 7]) / 7);
      }
    });
  };

  const confirmedChartData = getChartDataByType({ country, state, county, type: 'confirmed' });
  populateOneWeekAverageIncrease(confirmedChartData);

  const curedData = getChartDataByType({ country, state, county, type: 'recovered' });
  populateOneWeekAverageIncrease(curedData);

  const deathData = getChartDataByType({ country, state, county, type: 'deaths' });
  populateOneWeekAverageIncrease(deathData);

  const fatalityData = getFatalityChartData(confirmedChartData, deathData, country);

  const valueLogSwitch = (
    <>
      <span style={{ fontSize: 12, verticalAlign: 'text-bottom' }}>{t('chart.y_axis_scale')}</span>&nbsp;
      <Radio.Group value={type} size="small" onChange={e => setType(e.target.value)} className="radio-og">
        <Radio.Button value="value">{t('chart.value_axis')}</Radio.Button>
        <Radio.Button value="log">{t('chart.log_axis')}</Radio.Button>
      </Radio.Group>{' '}
      {!share && (
        <a href="https://blog.datawrapper.de/weeklychart-logscale/" target="_blank" className="log-info">
          <Icon type="info-circle" style={{ fontSize: '12px' }} />
        </a>
      )}
    </>
  );

  return (
    <>
      <div className="line-chart-container">
        {view === 'confirmed' && (
          <div className="chart-outter-wrap">
            <div className="chart-wrap">
              <div className="chart-menu-wrap">
                <Radio.Group
                  value={dimension}
                  size="small"
                  onChange={e => setDimension(e.target.value)}
                  className="radio-og">
                  <Radio.Button value="new">{t('chart.new_tab')}</Radio.Button>
                  <Radio.Button value="cumulative">{t('chart.cumulative_tab')}</Radio.Button>
                </Radio.Group>{' '}
                {valueLogSwitch}
              </div>
              <ProvinceTag />
              <DeferredRender deps={[type, dimension]}>
                <ReactEcharts
                  key={`confirm-${type}-${state}-${county}-${dimension}`}
                  option={getOption({
                    t,
                    lang: i18n.language,
                    titleText: t('chart.confirmed_case_chart_title'),
                    data: confirmedChartData,
                    dimension,
                    type,
                    colors: ['#ff6600', '#D53A35'],
                    names: {
                      cumulative: [t('chart.cumulative_cases')],
                      new: [t('chart.new_cases'), t('chart.seven_day_average')],
                    }[dimension],
                    graphic: [graphicText(`${state} ${county || ''}`)],
                    plotTrend: true,
                    isDarkScheme,
                    share,
                  })}
                  className="line-chart"
                />
              </DeferredRender>
            </div>
          </div>
        )}
        {view === 'deaths' && (
          <div className="chart-outter-wrap">
            <div className="chart-wrap">
              <div className="chart-menu-wrap">
                <Radio.Group
                  value={dimension}
                  size="small"
                  onChange={e => setDimension(e.target.value)}
                  className="radio-og">
                  <Radio.Button value="new">{t('chart.new_tab')}</Radio.Button>
                  <Radio.Button value="cumulative">{t('chart.cumulative_tab')}</Radio.Button>
                  <Radio.Button value="fatality">{t('chart.fatality_tab')}</Radio.Button>
                </Radio.Group>
                {!share && <CDCGuide icon />} {valueLogSwitch}
              </div>
              <ProvinceTag />
              <DeferredRender deps={[type, dimension]}>
                <ReactEcharts
                  key={`secondary-chart-${type}-${state}-${dimension}`}
                  option={getOption({
                    t,
                    lang: i18n.language,
                    titleText: t('chart.death_case_chart_title'),
                    dimension,
                    type,
                    graphic: [graphicText(`${state} ${county || ''}`)],
                    colors: ['#ae212d', '#5e7092'],
                    isDarkScheme,
                    share,
                    ...(dimension === 'fatality'
                      ? {
                          data: fatalityData,
                          names: [t('chart.fatality_rate')],
                          rate: true,
                          plotTrend: false,
                        }
                      : {
                          data: deathData,
                          names: {
                            cumulative: [t('chart.cumulative_deaths')],
                            new: [t('chart.new_deaths'), t('chart.seven_day_average')],
                          }[dimension],
                          plotTrend: true,
                        }),
                  })}
                  className="line-chart"
                />
              </DeferredRender>
            </div>
          </div>
        )}
        {view === 'recovered' && (
          <div className="chart-outter-wrap">
            <div className="chart-wrap">
              <div className="chart-menu-wrap">
                <Radio.Group
                  value={dimension}
                  size="small"
                  onChange={e => setDimension(e.target.value)}
                  className="radio-og">
                  <Radio.Button value="new">{t('chart.new_tab')}</Radio.Button>
                  <Radio.Button value="cumulative">{t('chart.cumulative_tab')}</Radio.Button>
                </Radio.Group>{' '}
                {valueLogSwitch}
              </div>
              <ProvinceTag />
              <DeferredRender deps={[type, dimension]}>
                <ReactEcharts
                  key={`secondary-chart-${type}-${state}-${dimension}`}
                  option={getOption({
                    t,
                    lang: i18n.language,
                    titleText: t('chart.recovered_case_chart_title'),
                    dimension,
                    type,
                    graphic: [graphicText(`${state} ${county || ''}`)],
                    colors: ['#26b8a4', '#26B8A4'],
                    isDarkScheme,
                    data: curedData,
                    names: {
                      cumulative: [t('chart.cumulative_cured')],
                      new: [t('chart.new_cured'), t('chart.seven_day_average')],
                    }[dimension],
                    plotTrend: true,
                    share,
                  })}
                  className="line-chart"
                />
              </DeferredRender>
            </div>
          </div>
        )}
      </div>
      <style jsx>{`
        .chart-outter-wrap {
          display: flex;
          flex-direction: column;

          :global(.line-chart) {
            height: 250px !important;
          }

          :global(.chart-wrap) {
            width: 100%;
            height: 270px;
            @media screen and (min-width: 576px) {
              height: 260px;
              :global(.line-chart) {
                margin-left: 10px;
                margin-right: 10px;
              }
            }
            :global(.response-line-chart) {
              margin-top: 30px;
            }
          }
        }

        .line-chart-container {
          position: relative;

          :global(.chart-wrap) {
            position: relative;
            :global(.chart-menu-wrap) {
              position: absolute;
              width: 100%;
              text-align: center;
              left: 8px;
              z-index: 10;
              top: 22px;
            }
            @media screen and (max-width: 576px) {
              padding: 0 5px;
            }
          }
        }
        .log-info {
          margin-left: 10px;
        }
        .hidden {
          height: 0px;
          visibility: hidden;
        }

        @media screen and (min-width: 576px) {
          :global(.response-line-chart) {
            margin-top: 0px !important;
          }
        }
      `}</style>
    </>
  );
};
