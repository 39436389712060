import React, { useEffect } from 'react';
import axios from 'axios';
import { NextSeo } from 'next-seo';
import dayjs from 'dayjs';
import _ from 'lodash';
import dynamic from 'next/dynamic';
import { Skeleton, Tabs, Spin } from 'antd';
import LazyLoad from 'react-lazyload';
import classNames from 'classnames';

import Header from '~/components/index/Header';
import Nav from '~/components/index/Nav';
import Fallback from '~/components/index/Fallback';
import Footer from '~/components/index/Footer';
import Stat from '~/components/index/Stat';
import VaccineStat from '~/components/index/VaccineStat';
import Amzn from '~/components/index/Amzn';
import Preview from '~/components/index/Preview';
import ShareCard from '~/components/index/share/ShareCard';
import { useTranslation } from 'react-i18next';
import LoopBox from '~/components/index/LoopBox';
import all from '~/data/overall';
import DonationBanner from '~/components/index/DonationBanner';
import GoogleAd from '~/components/GoogleAd';
import APAd from '~/components/APAd';
import ErrorBoundary from '../components/ErrorBoundary';
import useWindowDimensions from '~/hooks/useWindowDimensions';
import useLocation from '~/hooks/useLocation';

const LineChart = React.lazy(() => import('~/components/index/LineChart'));
const CaseResolutionChart = React.lazy(() => import('~/components/index/CaseResolutionChart'));
const MapAdapter = React.lazy(() => import('~/components/index/MapAdapter'));
const Trends = React.lazy(() => import('~/components/index/Trends'));
const DonutChart = React.lazy(() => import('~/components/index/DonutChart'));
// const RaceAndEthnicityCharts = React.lazy(() => import('~/components/index/RaceAndEthnicityCharts'));
const TotalBarChart = React.lazy(() => import('~/components/Test/TotalBarChart'));
const HospitalizedChart = React.lazy(() => import('~/components/index/HospitalizedChart'));

const StateTable = dynamic(() => import('~/components/index/StateTable'), { ssr: false });

export default ({ title_en, desc_en }) => {
  const [user, setUser] = React.useState(null);
  const [caseDataReady, setCaseDataReady] = React.useState(false);
  const { width: windowWidth } = useWindowDimensions();
  const isMobile = windowWidth <= 576;
  const { country, state } = useLocation();

  const { t, i18n } = useTranslation();
  useEffect(() => {
    const isWechatBrowser = /MicroMessenger/.test(window.navigator.userAgent);
    if (isWechatBrowser) {
      // for wechat users, use zh as default language when they visit homepage
      if (window.location.pathname === '/' && i18n.language !== 'zh') {
        i18n.changeLanguage('zh');
        window.history.replaceState(null, null, '/zh');
      }

      const url = encodeURIComponent(window.location.href.split('#')[0]);
      axios(`https://api.1point3acres.com/v1/api/wechat-share/config?url=${url}`).then(data => {
        const res = data.data;
        if (_.isNil(window.wx)) {
          console.error('wx undefined');
        } else {
          window.wx.config({
            debug: false,
            appId: res['appid'],
            timestamp: res['timestamp'],
            nonceStr: res['nonceStr'],
            signature: res['signature'],
            jsApiList: ['onMenuShareAppMessage', 'onMenuShareTimeline'],
          });
          window.wx.onMenuShareAppMessage({
            title: '北美新型冠状肺炎COVID-19疫情实时动态| 一亩三分地',
            desc: '持续更新汇总北美新型肺炎疫情及相关讨论',
            link: 'https://coronavirus.1point3acres.com',
            imgUrl: 'https://www.1point3acres.com/bbs/static/image/common/mu_logo.jpg',
          });
          window.wx.onMenuShareTimeline({
            title: '北美新型冠状肺炎COVID-19疫情实时动态| 一亩三分地',
            link: 'https://coronavirus.1point3acres.com',
            imgUrl: 'https://www.1point3acres.com/bbs/static/image/common/mu_logo.jpg',
          });
          window.wx.error(function(res) {
            console.log('Config failed, ' + res);
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    if (localStorage) {
      localStorage.setItem('visits_index', Math.max(_.toNumber(localStorage.getItem('visits_index')) || 0, 0) + 1);
    }
  }, []);

  useEffect(() => {
    if (_.isEmpty(country)) {
      return;
    }
    setCaseDataReady(true);
  }, [country]);

  const time = dayjs(all.modifyTime).format('YYYY-MM-DD HH:mm');

  const title = title_en ? title_en : t('app.title');
  const desc_template = desc_en ? desc_en : t('app.description');
  const description = desc_template
    .replace('{time}', time)
    .replace('{us}', all.usConfirmedCount)
    .replace('{ca}', all.caConfirmedCount);
  const lang = title_en ? '/en' : '';

  const charts = [
    {
      tabName: t('chart.case_chart_tab'),
      element: <LineChart view="confirmed" />,
    },
    {
      tabName: t('chart.death_chart_tab'),
      element: <LineChart view="deaths" />,
    },
    {
      tabName: country === 'us' ? 'Hospitalized' : t('chart.recovered_chart_tab'),
      element: country === 'us' ? <HospitalizedChart /> : <LineChart view="recovered" />,
    },
    ...(country === 'us'
      ? [
          {
            tabName: t('map.test'),
            element: <TotalBarChart inHome title={t('map.test')} />,
          },
        ]
      : []),
    ...(country === 'ca'
      ? [
          {
            tabName: t('chart.active_chart_tab'),
            element: <CaseResolutionChart />,
          },
        ]
      : []),
    // ...(country === 'us'
    //   ? [
    //       {
    //         tabName: t('chart.race_and_ethnicity_chart_tab'),
    //         element: <RaceAndEthnicityCharts />,
    //       },
    //     ]
    //   : []),
    ...(_.includes(['us', 'ca'], country)
      ? [
          {
            tabName: t('chart.case_by_state_chart_tab'),
            element: <DonutChart />,
          },
        ]
      : []),
  ];

  if (!caseDataReady) {
    return (
      <Spin
        tip={
          <div>
            <span style={{ fontSize: 18, paddingTop: 10 }}>{t('global.loading')}</span>
          </div>
        }
        size="large">
        <div style={{ width: '100%', height: 500 }} />
      </Spin>
    );
  }

  return (
    <>
      <div>
        <Header user={user} setUser={setUser} />
        <NextSeo
          title={title}
          description={description}
          canonical={`https://coronavirus.1point3acres.com${lang}`}
          additionalMetaTags={[
            {
              property: 'keywords',
              content: '疫情,冠状病毒,肺炎,新型肺炎,coronavirus,北美,美国,加拿大',
            },
          ]}
          twitter={{
            handle: '@1p3aDev',
            cardType: 'summary_large_image',
            site: '@1p3aDev',
          }}
          openGraph={{
            type: 'website',
            url: `https://coronavirus.1point3acres.com${lang}`,
            title: title,
            description: description,
            images: [
              {
                url: 'https://coronavirus.1point3acres.com/images/feiyan.jpeg',
                width: 800,
                height: 600,
                alt: 'Coronavirus',
              },
            ],
          }}
        />
        <div className="main-content">
          <DonationBanner />
          <ErrorBoundary>
            <Nav navItems={[{ id: 'stat' }, { id: 'map' }, { id: 'cases' }]} regionBannerIndexRange={[0, 2]} />
            <LoopBox />
          </ErrorBoundary>
          <ErrorBoundary>
            <Stat {...all} modifyTime={all.modifyTime} />
          </ErrorBoundary>
          <div style={{ width: '100%', maxWidth: 1200, margin: 'auto', marginBottom: 5, textAlign: 'center' }}>
            <APAd desktopId="7d0a948b-781a-42fa-9784-0e133c03aa58" mobileId="7b07f816-fd1e-4a0d-8de8-53635aec2d3d" />
          </div>
          <ErrorBoundary>{country === 'us' && <VaccineStat {...all} modifyTime={all.modifyTime} />}</ErrorBoundary>
          {process.browser && isMobile && (
            <ErrorBoundary>
              <LazyLoad height={1000}>
                <React.Suspense fallback={<Skeleton active className="card" />}>
                  <div>
                    <Tabs
                      defaultActiveKey={t('chart.case_chart_tab')}
                      renderTabBar={props => (
                        <div className="chart-tab-row">
                          {props.panels.map(panel => (
                            <div
                              key={panel.key}
                              className={classNames('chart-tab', {
                                'active-chart-tab': panel.key === props.activeKey,
                                'chart-tab-large': _.includes(['es', 'fr'], i18n.language),
                              })}
                              onClick={() => props.onTabClick(panel.key)}>
                              <span>{panel.props.tab}</span>
                            </div>
                          ))}
                        </div>
                      )}>
                      {charts.map(({ tabName, element }) => (
                        <Tabs.TabPane tab={tabName} key={tabName}>
                          <ErrorBoundary>{element}</ErrorBoundary>
                        </Tabs.TabPane>
                      ))}
                    </Tabs>
                  </div>
                  <div className="tips">{t('chart.notes')}</div>
                  <ErrorBoundary>
                    <MapAdapter />
                  </ErrorBoundary>
                </React.Suspense>
              </LazyLoad>
            </ErrorBoundary>
          )}
          {process.browser && !isMobile && (
            <ErrorBoundary>
              <LazyLoad height={1000}>
                <React.Suspense fallback={<Skeleton active className="card" />}>
                  <div className="tips">{t('chart.notes')}</div>
                  {_.chunk(charts, 3).map(chartChunk => (
                    <div className="chart-row" key={_.first(chartChunk).tabName}>
                      {chartChunk.map(({ element, tabName }) => (
                        <ErrorBoundary key={tabName}>{element}</ErrorBoundary>
                      ))}
                    </div>
                  ))}
                  <ErrorBoundary>
                    <MapAdapter />
                  </ErrorBoundary>
                </React.Suspense>
              </LazyLoad>
            </ErrorBoundary>
          )}
          <ErrorBoundary>
            <div id="cases">
              {process.browser && <GoogleAd slot="5247891128" height={200} width={300} />}
              <LazyLoad height={2000}>
                <StateTable />
              </LazyLoad>
            </div>
          </ErrorBoundary>
          {/* <ErrorBoundary>
            {process.browser && (
              <>
                <div style={{ width: '100%', maxWidth: 1200, margin: 'auto', paddingTop: 15 }}>
                  <GoogleAd slot="7660051648" />
                </div>
                <Preview />
                <Amzn />
              </>
            )}
          </ErrorBoundary> */}
          {/* <ErrorBoundary>
            {process.browser && (
              <React.Suspense fallback={<Skeleton active className="card" />}>
                <Trends />
              </React.Suspense>
            )}
          </ErrorBoundary> */}
          <ErrorBoundary>
            {/* <div style={{ maxWidth: 1000, margin: 'auto' }}>
              <GoogleAd slot="8494955276" height="200px" />
            </div> */}
            {process.browser && <Fallback />}
          </ErrorBoundary>
        </div>
        <Footer />
        <ErrorBoundary>
          <ShareCard />
        </ErrorBoundary>
      </div>
      <style jsx>{`
        .ad-container {
          display: inline-block;
          width: 100%;
          height: 120px;
          margin-left: auto;
          margin-right: auto;
        }
        .chart-row {
          align-items: flex-start;
          margin: 0px 10px;
        }
        .tips {
          position: relative;
          top: 0px;
          text-align: center;
          font-size: 10px;
          color: gray;
        }
        .chart-tab-row {
          margin: 10px 0 5px 0;
          overflow-x: scroll;
          white-space: nowrap;
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
        .chart-tab-row::-webkit-scrollbar {
          display: none;
        }
        .chart-tab {
          width: 68px;
          height: 35px;
          display: inline-block;
          margin: 0 2px;
          padding: 2px 0;
          vertical-align: top;
          text-align: center;
          background: #f7f7f7;
          border: 1px solid #e0e0e0;
          box-sizing: border-box;
          border-radius: 2px;
          font-size: 12px;
          line-height: 14px;
          color: #828282;
          span {
            white-space: normal;
            overflow-wrap: break-word;
          }
        }
        .chart-tab-large {
          width: 85px;
          height: 50px;
        }
        .active-chart-tab {
          background: #cbddf6;
          border: 1px solid #497b89;
          span {
            color: #497b89;
          }
        }
        @media screen and (min-width: 1000px) {
          .chart-row {
            display: flex;
            :global(& > *) {
              flex: 1;
            }
          }
        }
        @media screen and (max-width: 576px) {
          .ad-container {
            width: 100%;
            height: 80px;
          }
        }
      `}</style>
    </>
  );
};
