import React from 'react';

export default function useColorScheme() {
  const [isDarkScheme, setIsDarkScheme] = React.useState(false);

  React.useEffect(() => {
    if (window.matchMedia) {
      setIsDarkScheme(window.matchMedia('(prefers-color-scheme: dark)').matches);
      window.matchMedia('(prefers-color-scheme: dark)').addListener(e => setIsDarkScheme(e.matches));
    }
  }, []);

  return { isDarkScheme };
}
