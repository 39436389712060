import React from 'react';
import { Icon, Modal, Tooltip } from 'antd';
import ReactMarkdown from 'react-markdown';
import cdc_guide from '~/data/guide/cdc.md';

export default ({ icon }) => {
  const [visible, setVisible] = React.useState(false);
  return (
    <>
      {icon ? (
        <Icon type="info-circle" style={{ fontSize: '12px', marginLeft: '3px' }} onClick={() => setVisible(true)} />
      ) : (
        <a onClick={() => setVisible(true)} style={{ cursor: 'pointer', color: '#497b89' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;Why our US death numbers are different from CDC?
        </a>
      )}
      <Modal visible={visible} onCancel={() => setVisible(false)} footer={null}>
        <ReactMarkdown source={cdc_guide} escapeHtml={false} />
      </Modal>
    </>
  );
};
