import React from 'react';

export const ProductContext = React.createContext({
  product: '',
  setProduct: () => {},
});

export const VerifiedContext = React.createContext({
  verified: '',
  setVerified: () => {},
});

export const LogScaleContext = React.createContext({
  scale: 'value',
  setScale: () => {},
});

export const LocationContext = React.createContext({
  county: '',
  setCounty: () => {},
  state: '',
  setState: () => {},
  country: '',
  setCountry: () => {},
});
