import React from 'react';
import useWindowDimensions from '~/hooks/useWindowDimensions';

export default ({ desktopId, mobileId }) => {
  const adContainer = React.useRef();
  const { width: windowWidth } = useWindowDimensions();
  const isMobile = windowWidth <= 576;
  const adId = isMobile ? mobileId : desktopId;

  React.useEffect(() => {
    if (windowWidth && adContainer && adContainer.current.clientWidth >= 120) {
      try {
        let adpushup = (window.adpushup = window.adpushup || { que: [] });
        adpushup.que.push(function() {
          adpushup.triggerAd(adId);
        });
      } catch (err) {}
    }
  }, [windowWidth]);

  return <div ref={adContainer} id={adId} className="_ap_apex_ad" />;
};
