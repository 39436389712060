import React from 'react';
import { Modal } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import CDCGuide from '~/components/index/CDCGuide';

export default () => {
  const [active, setActive] = React.useState(0);
  const [isIntersecting, setIsIntersecting] = React.useState(true);
  const {
    i18n: { language },
  } = useTranslation();
  const intervalID = React.useRef(0);

  const messages = [
    <div>
      Our reporting is not affected by official COVID reporting moving from CDC to HHS.{' '}
      <a
        onClick={() =>
          Modal.info({
            title: 'About our reporting',
            content: (
              <div>
                <p>Our reporting is not affected by official COVID reporting moving from CDC to HHS.</p>
                <p>
                  Here is why: our data is aggregated from county and state level official sources. We still have access
                  to trustworthy data, as long as counties and states are reporting them. You can find links to our
                  sources in the tables on this site. We plan to track COVID data for as long as needed. Check our newly
                  accepted{' '}
                  <a href="https://arxiv.org/abs/2005.10948" target="_blank" rel="noopener">
                    BIOKDD 2020 paper
                  </a>{' '}
                  for aggregation methods.
                </p>
              </div>
            ),
            onOk() {},
          })
        }>
        Read More about Why
      </a>
    </div>,
    <div>
      Our paper has been accepted for presentation at BIOKDD 2020 on Aug 24, 2020.{' '}
      <a href="http://home.biokdd.org/biokdd20/regular_track.html" target="_blank" rel="noopener">
        Check it here
      </a>
      .
    </div>,
    <div>
      We published one of the world’s first technical papers on how data, technology and volunteers joined forces to
      fight COVID. <a href="https://arxiv.org/abs/2005.10948">Read more.</a>
    </div>,
    <div>
      Check out global COVID‑19 trends at <a href={`/${language}/world`}>world</a> page. Make your own comparison chart
      and share.
    </div>,
  ];

  const [data, setData] = React.useState(messages);

  React.useEffect(() => {
    if (language === 'zh') {
      setData([
        ...messages.concat(
          <div>
            直播：一起抗疫的日子，工作，学习，生活，方方面面。Follow us on&nbsp;
            <a href="https://www.1point3acres.com/bbs/forum-377-1.html" target="_blank">
              BBS
            </a>
            ,&nbsp;
            <a href="https://open.spotify.com/show/7Kp9cJVEbW5tmCC4LYgNT3" target="_blank">
              Spotify
            </a>
            &nbsp;and&nbsp;
            <a href="https://www.youtube.com/channel/UChit-KABNK1rc2hupwhTOWA" target="_blank">
              Youtube
            </a>
          </div>
        ),
      ]);
    } else {
      setData(messages);
    }
  }, [language]);

  const handleScroll = _.throttle(() => {
    const loopBox = document.querySelector('.loop-box');
    setIsIntersecting(loopBox ? window.scrollY < loopBox.offsetTop + loopBox.offsetHeight : false);
  }, 100);

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  React.useEffect(() => {
    if (isIntersecting) {
      intervalID.current = setInterval(() => {
        setActive(prevState => (data.length - 1 === prevState ? 0 : prevState + 1));
      }, 5000);
    } else {
      clearInterval(intervalID.current);
    }
    return () => {
      clearInterval(intervalID.current);
    };
  }, [isIntersecting, data]);

  return (
    <>
      <div className="loop-box-container">
        <ul className="loop-box">
          {data.map((d, i) => (
            <li
              key={i}
              className={classNames({
                active: i === active,
              })}>
              {d}
            </li>
          ))}
        </ul>
      </div>
      <style jsx>{`
        .loop-box-container {
          height: 40px;
          margin-top: 2px;
        }
        @media screen and (max-width: 576px) {
          .loop-box-container {
            min-height: 40px;
          }
        }
        ul {
          border: 1px solid #497b89;
          border-radius: 5px;
          font-size: 12px;
          padding: 3px;
          margin: 0px 0.5rem;
          list-style: none;
          position: relative;
          overflow: hidden;

          li {
            display: flex;
            align-items: center;
            position: absolute;
            top: -100%;
            opacity: 0;
            transition: all 0.5s;

            &.active {
              position: relative;
              top: 0px;
              opacity: 1;
            }
          }
        }
      `}</style>
    </>
  );
};
