import React from 'react';

const FONT_FAMILY = `-apple-system, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`;
const TIMES = 3;

export default ({ value, color = 'rgba(0, 0, 0, 0.65)', fontFamily, fontSize, fontWeight = '' }) => {
  const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);
  const [version, setVersion] = React.useState(0);
  const ref = React.useRef();
  const context = React.useRef();
  const font = `${fontWeight} ${fontSize * TIMES}px ${fontFamily || FONT_FAMILY}`;

  React.useEffect(() => {
    const ctx = ref.current.getContext('2d');
    ctx.font = font;
    ctx.textBaseline = 'hanging';
    const textMetrics = ctx.measureText(value);
    setWidth(textMetrics.width);
    setHeight(textMetrics.actualBoundingBoxDescent + 3 || fontSize * 3 + 3);
    setVersion(new Date());
    context.current = ctx;
  }, [value]);

  React.useEffect(() => {
    if (!context.current) return;
    const ctx = context.current;
    ctx.clearRect(0, 0, width, height);
    ctx.font = font;
    ctx.fillStyle = color;
    ctx.textBaseline = 'hanging';
    ctx.fillText(value, 0, 3);
  }, [version]);

  return (
    <>
      <canvas ref={ref} width={`${width}px`} height={`${height}px`} />
      <style jsx>{`
        canvas {
          width: ${width / TIMES}px;
          height: ${Math.ceil(height / TIMES)}px;
        }
      `}</style>
    </>
  );
};
