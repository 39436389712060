import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { Icon } from 'antd';
import { useTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import _ from 'lodash';

import useLocation from '~/hooks/useLocation';
import useColorScheme from '~/hooks/useColorScheme';
import summary from '~/data/test-us-overall';
import { thousandSeparate } from '~/utils';
import * as worldHelper from '~/utils/WorldHelper';
import { getGrowthRate } from '~/utils/ChartHelper';
import { TREND_OFFSET, DARK_TEXT_COLOR } from '~/utils/Constants';

function getChartOption({ data, isDarkScheme }) {
  const { t } = useTranslation();
  return {
    title: {
      right: 5,
      bottom: 5,
      text: `${t('chart.daily_growth_rate')}: ${Math.round(getGrowthRate(data.World.data).a * 100)}%`,
      textStyle: {
        color: isDarkScheme ? DARK_TEXT_COLOR : '#828282',
        fontSize: 14,
      },
    },
    grid: {
      top: 0,
      height: 60,
    },
    xAxis: {
      show: false,
      type: 'category',
      data: data.x,
    },
    yAxis: {
      show: false,
    },
    series: [
      {
        type: 'line',
        smooth: true,
        name: 'World',
        data: Array(TREND_OFFSET)
          .fill(null)
          .concat(data['World'].data),
      },
    ],
  };
}

const worldConfirmedData = worldHelper.filterTrendDataByCountries(worldHelper.getTrendData('relative_confirmed'), []);

export default () => {
  const { country } = useLocation();
  const { isDarkScheme } = useColorScheme();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <>
      <section className="card">
        <LazyLoad>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {country === 'us' && (
              <div className="preview-container">
                <a href={`/${language}/test`}>
                  <h3>{t('map.test')}</h3>
                  <Icon type="right" style={{ position: 'absolute', top: 5, right: 5, fontSize: 16 }} />
                </a>
                <section className="test-section">
                  <dl key="total">
                    <dd>{t('chart.total')}</dd>
                    <dt>{thousandSeparate(summary.Total)}</dt>
                  </dl>
                  <dl key="percentage">
                    <dd>{t('chart.positive')}%</dd>
                    <dt>{_.round((summary.Positive / summary.Total) * 100, 1)}%</dt>
                  </dl>
                </section>
              </div>
            )}
            <div className="preview-container">
              <a href={`/${language}/world`}>
                <h3>{t('chart.world')}</h3>
                <Icon type="right" style={{ position: 'absolute', top: 5, right: 5, fontSize: 16 }} />
              </a>
              <ReactEcharts
                option={getChartOption({
                  data: worldConfirmedData,
                  isDarkScheme,
                })}
                style={{ background: 'transparent', height: 80 }}
              />
            </div>
          </div>
        </LazyLoad>
      </section>
      <style jsx>{`
        .preview-container {
          flex: 1;
          position: relative;
          width: 50%;
          max-width: 500px;
          border: 1px solid #e0e0e0;
          border-radius: 4px;
          padding: 8px;
          &:first-child {
            margin-right: 8px;
          }
        }
        .test-section {
          color: #333;
          @media screen and (prefers-color-scheme: dark) {
            color: var(--dark-text-color);
          }
          display: grid;
          gap: 3px;
          margin-bottom: 3px;
          grid-template-columns: 60% auto;
          dl {
            flex: 1;
            text-align: center;
            font-size: 8px;
            margin: 0px;
            padding: 15px 0px;
            background: #f3f3f3;
            border-radius: 4px;

            dt {
              margin: 0px;
              font-size: 17px;
              line-height: 20px;
              font-weight: 600;
              letter-spacing: -1px;
              margin-top: 5px;
            }
            dd {
              font-size: 12px;
              line-height: 14px;
              font-weight: bold;
              margin: 0px;
            }
            &:nth-child(1) {
              background: rgba(71, 56, 142, 0.1);
              @media screen and (prefers-color-scheme: dark) {
                background: rgba(71, 56, 142, 0.3);
              }
              dt {
                color: #47388e;
                @media screen and (prefers-color-scheme: dark) {
                  color: #a193e2;
                }
              }
            }
            &:nth-child(2) {
              background: rgba(213, 58, 53, 0.1);
              @media screen and (prefers-color-scheme: dark) {
                background: rgba(213, 58, 53, 0.4);
              }
              dt {
                color: #d53a35;
              }
            }
          }
        }
      `}</style>
    </>
  );
};
