import React from 'react';

import { LogScaleContext } from '~/contexts';

export default () => {
  const { scale, setScale } = React.useContext(LogScaleContext);

  React.useEffect(() => {
    if (scale) {
      localStorage.setItem('logScale', scale);
    }
  }, [scale]);

  return [scale, setScale];
};
