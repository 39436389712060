import React from 'react';
import classNames from 'classnames';
import { Tag, Icon, Tooltip } from 'antd';
import _ from 'lodash';

import CanvasText from '~/components/CanvasText';
import { thousandSeparate } from '~/utils';
import { useTranslation } from 'react-i18next';
import useLocation from '~/hooks/useLocation';
import useWindowDimensions from '~/hooks/useWindowDimensions';
import useColorScheme from '~/hooks/useColorScheme';
import { getSummaries, toCountryText, FEATURED_REGIONS, getFlagUrl } from '~/utils/LocationHelper';

export default ({ share }) => {
  const { t, i18n } = useTranslation();
  const { country, state, county, setCountry } = useLocation();
  const { width: windowWidth } = useWindowDimensions();
  const isMobile = windowWidth <= 576;
  const summaries = getSummaries(country, state, county);
  const { isDarkScheme } = useColorScheme();
  const colors = isDarkScheme
    ? ['#FF8533', '#b6c0d3', country === 'us' ? '#ff6600' : '#09D7BC', '#DB434F']
    : ['#f60', '#5e7092', country === 'us' ? '#ff6600' : '#26b8a4', '#ae212d'];
  const visibleSummaries = _.filter(summaries, ([k, summary]) => !!summary);

  const renderSummaryRow = ([k, summary]) => (
    <ul key={k}>
      <li>
        <span>
          {{ World: t('stat.world'), US: t('stat.us'), CA: country === 'ca' ? t('stat.ca') : 'CA' }[k] ||
            toCountryText(k, i18n.language)}
        </span>
      </li>
      {summary &&
        summary.map(([count, increase], i) => {
          const unit = i === 3 && !Number.isNaN(Number(count)) ? '%' : '';
          const prefix = increase > 0 ? '+' : '';

          return (
            <li key={i}>
              {Boolean(increase) && (
                <CanvasText
                  value={`${prefix}${thousandSeparate(increase)}${unit}`}
                  color={colors[i]}
                  fontSize={12}
                  fontWeight="700"
                />
              )}
              <strong>
                <CanvasText
                  value={`${thousandSeparate(count)}${unit}`}
                  color={colors[i]}
                  fontSize={13}
                  fontWeight={Boolean(increase) ? undefined : '700'}
                />
              </strong>
            </li>
          );
        })}
    </ul>
  );

  return (
    <>
      <div className="summary-container">
        <div className="first-row">
          <ul className="title">
            <li>{t('stat.location')}</li>
            <li>{t('stat.confirmed')}</li>
            <li>{t('stat.death')}</li>
            <li style={{ letterSpacing: -1 }}>{country === 'us' ? t('chart.hospitalized') : t('stat.cured')}</li>
            <li style={{ letterSpacing: -1 }}>
              {country === 'us' ? (
                <span>
                  Test Pos%&nbsp;
                  {!share && (
                    <Tooltip title="Test Positive Rate in the last 7 days">
                      <Icon type="info-circle" />
                    </Tooltip>
                  )}
                </span>
              ) : (
                t('stat.fatality')
              )}
            </li>
          </ul>
          {renderSummaryRow(_.first(visibleSummaries))}
        </div>

        <div className="summary-rows">{_.slice(visibleSummaries, 1).map(item => renderSummaryRow(item))}</div>
      </div>
      <style jsx>{`
        .summary-container {
          border-radius: 5px;
          display: flex;
          flex-direction: column;
          padding: 3px;

          :global(ul) {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            padding: 0px;
            list-style: none;
            text-align: center;
            margin: 0px;
            font-weight: 700;
            font-size: 12px;

            &:not(:first-child) {
              min-height: 50px;
              max-height: 100px;
              @each $color, $i in #f60, #5e7092, #26b8a4, #ae212d {
                li:nth-child(calc($i + 2)) {
                  color: $color;
                }
              }
            }
            :global(&:not(:nth-child(1))) {
              :global(li):not(:nth-child(1)) {
                font-family: monospace;
              }
            }

            :global(li) {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              overflow: hidden;

              & > :global(div):first-child {
                font-size: 0.55rem;
              }
              & > :global(div):last-child {
                font-size: 1.2rem;
                font-weight: 600;
              }
              :global(span):first-child {
                font-weight: normal;
                font-size: 12px;
              }
              :global(strong) {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                margin-top: 5px;
              }
            }
          }
        }
        .summary-rows {
          background: url('/images/logo-watermark.png') no-repeat center;
          @media screen and (prefers-color-scheme: dark) {
            background: url('/images/logo-watermark-dark.png') no-repeat center;
          }
        }
        ul.title {
          height: 30px;
          background: #fff;
          color: rgba(0, 0, 0, 0.65);

          li {
            line-height: 15px;
          }
          @each $color, $i in #f2f7fd, #db9d90, #b6c0d3 {
            li:nth-child(calc($i + 1)) {
              background: $color;
            }
          }
          li:nth-child(4) {
            background: ${country === 'us' ? '#ff6600' : '#09D7BC'};
          }
          li:nth-child(5) {
            background: #e3e7f3;
          }
          @media screen and (prefers-color-scheme: dark) {
            background: transparent;
            color: #3d3d3d;
          }
        }
        .first-row {
          margin-bottom: 10px;
          border-top: 0px solid;
          box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
          :global(li):first-child :global(span) {
            font-weight: bold !important;
          }
        }
        .flag-row {
          display: inline;
          padding-top: 15px;
          margin-bottom: 10px;
          overflow: auto;
          @media screen and (max-width: 576px) {
            white-space: nowrap;
          }
        }
      `}</style>
    </>
  );
};
