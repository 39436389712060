import React from 'react';
import { useTranslation } from 'react-i18next';
import { unstable_scheduleCallback, unstable_IdlePriority } from 'scheduler';

import useLocation from '~/hooks/useLocation';

const DeferredRender = ({ children, deps = [] }) => {
  const [renderToken, setRender] = React.useState();
  const { i18n } = useTranslation();
  const { country, state, county } = useLocation();

  React.useEffect(() => {
    unstable_scheduleCallback(unstable_IdlePriority, () => {
      setRender(Date.now());
    });
  }, [i18n.language, country, state, county, ...deps]);

  return React.useMemo(() => children, [renderToken]);
};

export default DeferredRender;
