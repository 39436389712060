import React from 'react';
import { useTranslation } from 'react-i18next';
import dynamic from 'next/dynamic';

import DataSourceButton from '~/components/index/DataSourceButton';
const VaccineSummary = dynamic(() => import('~/components/index/VaccineSummary'), { ssr: false });

export default () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="card" id="stat">
        <div className="title">
          <h2>{t('stat.vaccine_summary')}</h2>
          <DataSourceButton dataType="vaccine" />
        </div>
        <VaccineSummary />
      </div>
      <style jsx>{`
        .due {
          font-size: 0.8rem;
          color: #888;
          font-weight: 400;
        }
        .title {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          margin-right: 10px;
        }
      `}</style>
    </>
  );
};
